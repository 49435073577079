













import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { ProjectDocumentsMutations } from '@store/modules/project-documents/Types';
import { namespace } from 'vuex-class';
import { IDocument } from '@store/modules/project-documents/Interfaces';
import { IWork, IWorkListState } from '@store/modules/work-list/interfaces/Interfaces';

const NSWorkList = namespace('storeWorkList');
const NSDocuments = namespace('storeProjectDocuments');

@Component({
    name: 'PopupFiltersDocuments',
    components: {
        PopupFilters: () => import('@components/popups/PopupFilters.vue'),
    }
})

export default class PopupFiltersDocuments extends Vue {
    $refs!: {
        popupFilter: HTMLFormElement,
    }

    mounted() {
        this.setOptions();
    }

    popupData: any[] = [
        {
            title: 'Автор',
            block: 'author',
            type: 'select',
            key: 'author.fullName',
            options: [],
            selected: [],
        },{
            title: 'Период',
            titles: ['Дата начала периода', 'Дата окончания периода'],
            block: 'createDate',
            type: 'dateRange',
            key: 'createDate',
            datesRange: {},
        },{
            title: 'Статус',
            block: 'status',
            type: 'select',
            key: 'status.title',
            options: [],
            selected: [],
        },
    ];

    @NSDocuments.Getter('filters') filters!: any[];
    @NSDocuments.Getter('viewMode') viewMode!: string;
    @NSDocuments.Getter('packPDList') packPDList!: any;
    @NSDocuments.Getter('documents') documents!: IDocument[];
    @NSDocuments.Getter('filtersApply') filtersApply!: boolean;
    @NSDocuments.Getter('compositeShow') compositeShow!: boolean;
    @NSDocuments.Getter('allReadProjectDocuments') allReadDocuments!: IDocument[];
    @NSDocuments.Getter('allReadDocumentsIncludeExecutive') allReadDocumentsPlusExecutive!: IDocument[];
    @NSWorkList.State((state: IWorkListState) => state.viewingWork) viewingWork!: IWork;
    @NSWorkList.State((state: IWorkListState) => state.viewingPackExecutive) viewingPackExecutive!: any;
    @NSDocuments.Mutation(ProjectDocumentsMutations.M_SET_FILTERS) setFilters!: (payload: any[]) => void;
    @NSDocuments.Mutation(ProjectDocumentsMutations.M_SET_FILTERS_APPLY) setFiltersApply!: (payload: boolean) => void;

    @Watch('filtersCount')
    watchCount() {
        if (this.filtersCount !== null) {
            this.$emit('set-filters-preview', this.popupData);
        }
    }

    get filtersCount(): number | null {
        let output: IDocument[] = this.allReadDocuments;
        if (this.viewMode === 'executiveDocs') {
            output = this.allReadDocumentsPlusExecutive;
        }
        if (this.viewMode === 'executiveDocs' && this.compositeShow && this.viewingWork && this.viewingWork.constructs) {
            let allDocsIds: string[] = [];
            this.viewingWork.constructs.forEach(construct => {
                const docsIds = construct.documents.map(doc => doc.uuid);
                allDocsIds = allDocsIds.concat(docsIds);
            });
            output = output.filter(doc => allDocsIds.includes(doc.uuid));
        }
        if (this.viewMode === 'executiveDocs' && !this.compositeShow && this.viewingPackExecutive.length && this.packPDList) {
            let allDocsIds: string[] = [];
            const packPDItem = this.packPDList.filter(packItem => packItem.title === this.viewingPackExecutive)[0];
            if (packPDItem && packPDItem.packED) {
                packPDItem.packED.forEach(item => {
                    allDocsIds = allDocsIds.concat(item.documentsIds);
                });
            }
            output = output.filter(doc => allDocsIds.includes(doc.uuid));
        }
        this.filters.forEach((filterObject) => {
            if (filterObject.type === 'select') {
                if (filterObject.key === 'author.fullName') {
                    output = output.filter(
                        doc => filterObject.selected.includes(doc.author.fullName),
                        doc => !doc.isFolder,
                    );
                }
                if (filterObject.key === 'status.title') {
                    output = output.filter(
                        doc => doc.status ? filterObject.selected.includes(doc.status.title) : false,
                        doc => !doc.isFolder,
                    );
                }
            }
            if (filterObject.type === 'dateRange' && (filterObject.datesRange.dateFrom || filterObject.datesRange.dateTo)) {
                output = output.filter(
                    doc => filterObject.datesRange.dateFrom ? Date.parse(doc[filterObject.key]) >= Date.parse(filterObject.datesRange.dateFrom) : true,
                    doc => !doc.isFolder,
                );
                output = output.filter(
                    doc => filterObject.datesRange.dateTo ? Date.parse(doc[filterObject.key]) <= Date.parse(filterObject.datesRange.dateTo) : true,
                    doc => !doc.isFolder,
                );
            }
        });
        return output.length;
    }

    applyFilters() {
        this.setFiltersApply(true);
        this.closePopup();
    }

    cancelFilters() {
        this.setFiltersApply(false);

        this.popupData.forEach((item) => {
            if (item.type === 'checkboxes' || item.type === 'select') {
                item.selected = [];
            }
            if (item.type === 'dateRange') {
                item.datesRange = {};
            }
        });

        if (this.$refs.popupFilter) {
            this.$refs.popupFilter.resetFilters();
        }

        this.setFilters([]);
    }

    setOptions() {
        this.popupData.forEach((item) => {
            if (item.block === 'author') {
                item.options = this.allAuthors();
            }
            if (item.block === 'status') {
                item.options = this.allStatuses();
            }
        })
    }

    allAuthors(): any[] {
        const added: string[] = [];
        const output: any[] = [];
        if (this.documents) {
            this.documents.forEach((item) => {
                if (!item.isFolder && item.author && item.author.fullName && !added.includes(item.author.fullName)) {
                    added.push(item.author.fullName);
                    output.push({label: item.author.fullName});
                }
            })
        }
        return output;
    }

    allStatuses(): any[] {
        const added: string[] = [];
        const output: any[] = [];
        if (this.documents) {
            this.documents.forEach((item) => {
                if (!item.isFolder && item.status && item.status.title && !added.includes(item.status.title)) {
                    added.push(item.status.title);
                    output.push({label: item.status.title});
                }
            })
        }
        return output;
    }

    changeSelected(e, byPreview = false) {
        for (const item of this.popupData) {
            if ((item.type === 'checkboxes' || item.type === 'select') && item.title === e.section) {
                item.selected = e.selected;
            }
            if (item.type === 'dateRange' && item.title === e.section) {
                item.datesRange = e.datesRange;
            }
        }
        this.updateStoreFilters();
        if (byPreview && !e.datesRange) {
            this.$refs.popupFilter.changeSelectedData(e);
        }
        if (byPreview && e.datesRange) {
            this.$refs.popupFilter.changeSelectedDataDate(e);
        }
    }

    updateStoreFilters() {
        const filters: any[] = [];
        this.popupData.forEach((item) => {
            if ((item.type === 'checkboxes' || item.type === 'select') && item.selected.length) {
                filters.push(item);
            }
            if (item.type === 'dateRange') {
                filters.push(item);
            }
        });
        this.setFilters(filters);
    }

    openPopup() {
        this.$refs.popupFilter.openPopup();
    }

    closePopup() {
        this.$refs.popupFilter.closePopup();
    }
}
